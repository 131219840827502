
    <template>
      <section class="markdown-container theme-default-content">
      <head class="markdown"></head><body class="markdown"><h1 class="markdown">Theme 主题</h1>
<demo-block class="markdown">
        <div class="markdown"><p class="markdown">由<code class="markdown">type</code>属性来选择tag的类型，也可以通过<code class="markdown">color</code>属性来自定义背景色。</p>
</div>
        <template slot="source" class="markdown"><element-demo0 class="markdown"></element-demo0></template>
        <template slot="highlight" class="markdown"><pre v-pre="" class="markdown"><code class="html markdown">&lt;cube-tag type="primary"&gt;标签一&lt;/cube-tag&gt;
&lt;cube-tag type="success"&gt;标签二&lt;/cube-tag&gt;
&lt;cube-tag type="info"&gt;标签三&lt;/cube-tag&gt;
&lt;cube-tag type="warning"&gt;标签四&lt;/cube-tag&gt;
&lt;cube-tag type="danger"&gt;标签五&lt;/cube-tag&gt;
</code></pre></template></demo-block></body>
      </section>
    </template>
    <script>
    
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cube-tag',{attrs:{"type":"primary"}},[_vm._v("标签一")]),_vm._v(" "),_c('cube-tag',{attrs:{"type":"success"}},[_vm._v("标签二")]),_vm._v(" "),_c('cube-tag',{attrs:{"type":"info"}},[_vm._v("标签三")]),_vm._v(" "),_c('cube-tag',{attrs:{"type":"warning"}},[_vm._v("标签四")]),_vm._v(" "),_c('cube-tag',{attrs:{"type":"danger"}},[_vm._v("标签五")])],1)}
var staticRenderFns = []

  
    const democomponentExport = {}
    return {
      render,
      staticRenderFns,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  