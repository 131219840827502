// 控件类型
const controlType = {
  // 颜色分类选择器
  colorPickerGroup: "color-picker-group",
  // 开关
  switch: "switch",
  // 下拉框
  select: "select",
  // 滑块
  slider: "slider",
  // 取色器
  colorPicker: "color-picker",
  // 输入框
  input: "input"
}

// 主题item的配置
const themeConfig = {
  // 浅色，唯一id
  id: "light",
  //   主题标题
  name: "浅色",
  //   主题文案
  desc: "一句话描述主题",
  // 标签
  tag: "新上架",
  //   主题背景图片
  cover: "https://www.baidu.com/img/PCtm_d9c8750bed0b3c7d089fa7d55720d6cf.png",
  //   主题颜色
  color: "#1259E9",
  list: []
}

const getThemeConfig = () => {
  return {
    ...themeConfig,
    list: []
  }
}

// 主题编辑器json
const themeJson = {
  // 全局配置
  settings: {
    // 实时保存
    autoSave: false,
    // 是否支持自定义
    enableCustom: true,
    // 是否缩放
    zoom: false,

    // 长度类型
    fontUnit: "px", // px，如果是 rem，要换算成 px 方便
    // 选择器样式名,移动端使用 uni-page-body
    // rootSelector: "uni-page-body",
    rootSelector: ":root, body",
    themeStorageName: "themeStyleAdjustment"
    // iframeSelector: ".mobile-simulator iframe"
  },
  // 默认主题
  defaultTheme: ["default", "light"],
  // 紧凑程度，加载的时候顶部显示和主题切换类似的效果，但是整体缩小，单选
  scaleStyle: {
    default: "light",
    styles: []
  },
  // 内置主题切换
  themeList: [],

  // 主题风格调整
  // themeStyleAdjustment: {
  //   // 当前选中的主题id
  //   themeId: ["default", "light"],
  //   // 是否自定义
  //   customConfig: {
  //     controlType: controlType.switch,
  //     value: false,
  //     // 显示文本
  //     text: "自定义"
  //   }
  // },

  // 全局样式编辑，无权限返回 globalStyleEditing: null
  globalStyle: [
    // 色彩
    {
      name: "色彩",
      children: [
        {
          variable: "--color-primary-0",
          leftIcon: "cube-sales-outline",
          defaultValue: "#000",
          valueText: "实心主要按钮背景色",
          controller: {
            type: "color-picker"
          }
        },
        {
          variable: "--el-tag-padding",
          leftIcon: "cube-sales-outline",
          defaultValue: 12,
          fontUnit: "px",
          controller: {
            type: "slider",
            options: {
              min: 0,
              max: 128,
              step: 1
            }
          },
          valueText: "通用间距 2px"
        }
      ]
    }
  ],

  // 组件样式编辑 无权限返回 componentStyleEditing: null
  componentStyle: [
    {
      name: "通用",
      children: [
        {
          name: "Tag 边框",
          // 无子级
          children: null,
          rightConfig: [
            // 颜色
            {
              name: "色彩",
              children: [
                // {
                //   variable: "--el-tag-blue-bg-color",
                //   leftIcon: "cube-sales-outline",
                //   defaultValue: "#fff",
                //   controller: {
                //     type: "color-picker"
                //   },
                //   valueText: "通用间距 2px"
                // }
                {
                  variable: "--el-tag-blue-bg-color",
                  leftIcon: "cube-sales-outline",
                  defaultValue: "var(--color-primary-0)",
                  controller: {
                    type: "color-picker"
                  },
                  valueText: "通用间距 2px"
                }
                // {
                //   variable: "--cdm-theme-header-font-size",
                //   leftIcon: "cube-sales-outline",
                //   defaultValue: "var(--cdm-theme-font-size-subhead)",
                //   valueText: "标题字体大小",
                //   fontUnit: "",
                //   controller: {
                //     type: "slider",
                //     options: {
                //       min: 0,
                //       max: 100,
                //       step: 1
                //     }
                //   }
                // }
              ]
            }
          ]
        }
      ]
    }
  ]
}
// window.themeJson = themeJson
export default themeJson
